<template>
	<div class="pages-aboutUS">
		
		<img style="width: 100%;" src="https://dh123.hndh6666.com/images/temp2_top.png" />
		
		<div class="bgbox-white">
			<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 20, offset: 2 }" :lg="{ span: 16, offset: 4 }">
				<div class="module1-conts">
					<div class="module-title">
						<span class="title-cn">数字营销</span>
						<span class="title-en">LEARNING PRODUCTS</span>
					</div>
					
					<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 12 }" :lg="{ span:24 }">
						<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 12 }" :lg="{ span:4 }">
							<div class="module1-item wow slideInLeft" style="">
								<img src="https://dh123.hndh6666.com/images/temp2_01.png" />
								<div class="title">SCRM</div>
								<div class="text">社会型关系客户管理</div>
							</div>
						</el-col>
						<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 12 }" :lg="{ span:4 }">
							<div class="module1-item wow slideInLeft" style="">
								<img src="https://dh123.hndh6666.com/images/temp2_02.png" />
								<div class="title">数字营销</div>
								<div class="text">精准有效的激励您的客户</div>
							</div>
						</el-col>
						<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 12 }" :lg="{ span:4 }">
							<div class="module1-item wow slideInLeft" style="">
								<img src="https://dh123.hndh6666.com/images/temp2_03.png" />
								<div class="title">单客经营</div>
								<div class="text">经营您的每一个客户</div>
							</div>
						</el-col>
						<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 12 }" :lg="{ span:4 }">
							<div class="module1-item wow slideInLeft" style="">
								<img src="https://dh123.hndh6666.com/images/temp2_04.png" />
								<div class="title">积分商城</div>
								<div class="text">为您的客户提供增值服务</div>
							</div>
						</el-col>
						<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 12 }" :lg="{ span:4 }">
							<div class="module1-item wow slideInLeft" style="">
								<img src="https://dh123.hndh6666.com/images/temp2_05.png" />
								<div class="title">卡券系统</div>
								<div class="text">拉电商流量的利器</div>
							</div>
						</el-col>
						<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 12 }" :lg="{ span:4 }">
							<div class="module1-item wow slideInLeft" style="">
								<img src="https://dh123.hndh6666.com/images/temp2_06.png" />
								<div class="title">数据分析</div>
								<div class="text">满足个性化消费需求</div>
							</div>
						</el-col>
					</el-col>
				</div>
			</el-col>
		</div>
		
		<div class="bgbox-white">
			<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 20, offset: 2 }" :lg="{ span: 16, offset: 4 }">
				<div class="module1-conts">
					<div class="wow slideInRight" style="display: flex;flex-direction: row;align-items: center;">
						<img style="width: 90px;max-height: 90px;" src="https://dh123.hndh6666.com/images/temp2_07.png" />
						<div style="display: flex;flex-direction: column;color: #0075C1;margin-left: 20px;">
							<span style="font-size: 30px;">客户大数据分析</span>
							<span style="font-size: 15px;">面向用户经营实体店，优化供应链，提供令人心动的产品</span>
						</div>
					</div>
				</div>
			</el-col>
		
			<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 20, offset: 2 }" :lg="{ span: 16, offset: 4 }">
				<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:12 }">
					<!-- <div class="core-css01 slideInLeft"> -->
						<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:12 }">
							<div class="miBox2">
								<img style="" src="https://dh123.hndh6666.com/images/temp2_08.png" />
							</div>
						</el-col>
						<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:12 }">
							<div class="core-txt">
								<div class="title">多维度客户分析</div>
								<div class="text1" style="margin-top: 15px;">了解每个实体店的客户分布区域，客户构
								成，客户消费能力，光顾频率等信息，以
								帮助选择更合适的开店地址及留存高价值
								客户
								</div>
							</div>
						</el-col>
					<!-- </div> -->
				</el-col>
				<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:12 }">
					<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:12 }">
						<div class="miBox2">
							<img style="" src="https://dh123.hndh6666.com/images/temp2_09.png" />
						</div>
					</el-col>
					<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:12 }">
						<div class="core-txt">
							<div class="title">构建您自己的仪表盘</div>
							<div class="text1" style="margin-top: 15px;">创建视觉效果突出的图表和报告，一目了
							然的显示关键业务指标，便于您理解当前
							状况。</div>
						</div>
					</el-col>
				</el-col>
				<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:12 }">
					<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:12 }">
						<div class="miBox2">
							<img style="" src="https://dh123.hndh6666.com/images/temp2_10.png" />
						</div>
					</el-col>
					<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:12 }">
						<div class="core-txt">
							<div class="title">客户价值分析</div>
							<div class="text1" style="margin-top: 15px;">分析出您客户群体中不同阶段的客
							户，从而采取更精准的营销来帮助
							您提高销售额</div>
						</div>
					</el-col>
				</el-col>
				<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:12 }">
					<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:12 }">
						<div class="miBox2">
							<img style="" src="https://dh123.hndh6666.com/images/temp2_11.png" />
						</div>
					</el-col>
					<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:12 }">
						<div class="core-txt">
							<div class="title">客户产品、品牌分析</div>
							<div class="text1" style="margin-top: 15px;">分析客户偏好，是哪个品牌的忠实粉丝。
							是晴天还是雨天，是上午还是下午，深入
							了解促进交易的关键点。
							</div>
						</div>
					</el-col>
				</el-col>
			</el-col>
		</div>
		
		<div class="bgbox-white">
			<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 20, offset: 2 }" :lg="{ span: 16, offset: 4 }">
				<div class="module1-conts">
					<div class="module-title">
						<span class="title-cn">五大超级聚合</span>
						<span class="title-en">FIVE SUPER CONVERSIONS</span>
					</div>
				</div>
			</el-col>
		
			<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 20, offset: 2 }" :lg="{ span: 16, offset: 4 }">
				<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:12 }">
					<div class="core-css01 slideInLeft" style="padding: 20px;">
						<img style="width: 100px;height: 100px;" src="https://dh123.hndh6666.com/images/temp2_12.png" />
						<div class="core-txt">
							<div class="title2">支付方式全聚合</div>
							<div class="text1" style="margin-top: 15px;">支持官方直联、银行间联、第三方间联通道</div>
						</div>
					</div>
				</el-col>
				<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:12 }">
					<div class="core-css01 slideInRight" style="padding: 20px;">
						<img style="width: 100px;height: 100px;" src="https://dh123.hndh6666.com/images/temp2_13.png" />
						<div class="core-txt">
							<div class="title2">调用方式全拥有</div>
							<div class="text1" style="margin-top: 15px;">独立支付产品、SDK调用、API接口、SaaS相赁满足合作商、商户多种合作模式需求。</div>
						</div>
					</div>
				</el-col>
				<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:12 }">
					<div class="core-css01 slideInRight" style="padding: 20px;">
						<img style="width: 100px;height: 100px;" src="https://dh123.hndh6666.com/images/temp2_15.png" />
						<div class="core-txt">
							<div class="title2">商户类型全支持</div>
							<div class="text1" style="margin-top: 15px;">支持企事业单位、全国大型连
							锁客户、地方KA、小型连锁、街
							边店、档口等大型、中型、小微
							企业入驻德合,提供支付及相关
							业务服务。</div>
						</div>
					</div>
				</el-col>
				<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:12 }">
					<div class="core-css01 slideInRight" style="padding: 20px;">
						<img style="width: 100px;height: 100px;" src="https://dh123.hndh6666.com/images/temp2_16.png" />
						<div class="core-txt">
							<div class="title2">结算方式全具备</div>
							<div class="text1" style="margin-top: 15px;">为商户提供灵活的结算方式，
							满足商户满额、定时、个性化
							时间段结算,为连锁客户提供
							定额分账、定比例分账、个性
							化分账功能。</div>
						</div>
					</div>
				</el-col>
				<el-col :xs="{ span: 24 }" :sm="{ span: 24 }" :md="{ span: 24 }" :lg="{ span:12 }">
					<div class="core-css01 slideInLeft" style="padding: 20px;">
						<img style="width: 100px;height: 100px;" src="https://dh123.hndh6666.com/images/temp2_14.png" />
						<div class="core-txt">
							<div class="title2">支付场景全覆盖</div>
							<div class="text1" style="margin-top: 15px;">H5支付码、小程序商家码、
							独立开票码等台卡类产品，
							智能POS、刷脸IoT等智能
							硬件，德合商服收银台插件类
							产品，Saas软件类产品，全
							面覆盖商户支付场景，满足
							商户不同经营场景需求。</div>
						</div>
					</div>
				</el-col>
			</el-col>
		</div>
		
	</div>
</template>

<script>

	export default {
		components: {
		},
		data() {
			return {
				showBigImg: false,
				bigImgSrc: null,
				center: {
					lng: 0,
					lat: 0
				},
				zoom: 3
			};
		},
		methods: {
			maphandler() {
				this.center.lng = 113.036666
				this.center.lat = 28.145000
				this.zoom = 18
			}
		}
	};
</script>
<style lang="scss">
	.BMap_cpyCtrl,
	.anchorBL {
		display: none;
	}

	.BMapLabel {
		padding: 6px !important;
		border: 1px solid #0066cc !important;
		color: #0066cc !important;
		left: -45px !important;
		top: -30px !important;
		font-weight: bold !important;
	}

	// big img
	.dialog-bigimg {
		box-shadow: none;
		background: transparent;
		margin: 5% auto 0 !important;
		width: fit-content !important;
		height: auto !important;
		overflow: hidden;

		.el-dialog__header {
			display: none;
		}

		.el-dialog__body {
			padding: 0;
			width: fit-content !important;
			height: auto !important;

			img {
				max-width: 100%;
				max-height: 100%;
				margin: 0 auto;
				display: block;
			}
		}
	}
</style>
<style lang="scss" scoped>
	.article {
		font-size: 16px;
		text-align: center;
	}

	.card-show {
		overflow: hidden;

		.item {
			float: left;
			width: 100%;
			margin: 30px 0px 0px 0px;
			// background: #ff5500;
			padding: 20px 10px 20px 10px;
			box-sizing: border-box;
			transition: all 0.3s ease;

			h3 {
				font-size: 20px;
				color: #333;
				padding-bottom: 5px;
			}

			h4 {
				height: 30px;
				font-size: 13px;
				color: #333;
				font-weight: 400;
				margin-bottom: 20px;
			}

			p {
				height: 60px;
				font-size: 16px;
				color: #666;
				margin: 20px 0;
			}

			.img-box {
				width: 100%;
				max-height: 390px;
				overflow: hidden;

				img {
					max-width: 471px;
					max-height: 390px;
					object-fit: cover;
					transition: all 0.3s ease;
				}
			}
		}

		.item:hover {
			cursor: pointer;
			// box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
			transform: translate3d(0, -2px, 0);

			img {
				transform: scale(1.1);
			}

			.el-button {
				color: #409EFF;
				border-color: #c6e2ff;
				background-color: #ecf5ff;
			}
		}

		.item:last-child {
			margin-right: 0;
		}

		.swiper-button-prev,
		.swiper-button-next {
			outline: none;
			height: 30px;
		}
	}

	.bgbox-white {
		padding: 50px 10px;
		overflow: hidden;
		background: #fff;
		box-sizing: border-box;
	}

	.the-banner {
		height: 400px;
		background-repeat: no-repeat;
		background-position: center;
	}

	.module-title {
		text-align: center;
		padding-bottom: 20px;

		.title-cn {
			display: block;
			font-size: 30px;
			font-weight: bold;
			color: #1f1f1f;
		}

		.title-en {
			display: block;
			font-size: 12px;
			font-weight: 400;
			color: #757575;
			line-height: 20px;
		}
	}
	

	.core-css01{
		width: 100%;
		margin-top: 10px;
		margin-bottom: 20px;
		display: flex;
		flex-direction: row;
		// justify-content: center;
		align-items: center;
	}
	.miBox2{
		
		img{
			width: 300px;
			height: 200px;
		}
	}
	
	.core-txt{
		margin-top: 20px;
		margin-left: 10px;
		padding-right: 20px;
		display: flex;
		flex-direction: column;
	}
	
	.title{
		color: #060404;
		font-size: 20px;
		font-weight: 700;
	}
	
	.title2{
		color: #0075C1;
		font-size: 24px;
		font-weight: 700;
	}
	
	.line-group{
		position: relative;
	
		.line1{
			width: 300px;
			height: 1px;
			background-color: #919191;
		}
		
		.line2{
			position: absolute;
			width: 50px;
			height: 3px;
			background-color: #DE633B;
			top: -1px;
		}
	}
	
	.text{
		color: #231815;
		font-size: 14px;
	}
	
	.text1{
		margin-top: 5px;
		color: #525252;
		font-size: 15px;
	}

	.module1-conts {
		p {
			font-size: 16px;
			color: #333;
			text-indent: 2em;
		}
		
		.module1-item{
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			
			img{
				max-width: 80px;
				max-height: 80px;
			}
			
			.title{
				margin-top: 10px;
				color: #3E3A39;
				font-size: 20px;
			}
			.text{
				color: #717071;
				font-size: 15px;
			}
		}

		.item-list {
			width: 100%;
			padding: 40px 0;
			box-sizing: border-box;
			overflow: hidden;

			.item {
				width: 120px;
				margin: 0 auto;
				text-align: center;
				padding: 20px 0;
				box-sizing: border-box;

				img {
					display: inline-block;
					margin-bottom: 16px;
				}

				span {
					display: block;
					font-size: 14px;
					color: #1f1f1f;
				}
			}
		}

		.img-list {
			max-width: 1220px;
			margin: 0 auto;
			overflow: hidden;

			.img-item {
				width: 100%;
				height: 260px;
				overflow: hidden;
				margin-bottom: 20px;

				img {
					display: block;
					height: 100%;
					margin: 0 auto;
				}
			}
		}
	}

	.bgbox-img {
		padding: 50px 10px;
		overflow: hidden;
		background-position: center center;
		box-sizing: border-box;

		.module-title {
			text-align: center;
			padding-bottom: 40px;

			.title-cn {
				display: block;
				font-size: 30px;
				font-weight: bold;
				color: #ffffff;
			}

			.title-en {
				display: block;
				font-size: 12px;
				font-weight: 400;
				color: #ffffff;
				line-height: 20px;
			}
		}

		.module2-conts {
			overflow: hidden;

			.conts-list {
				padding: 20px 0 0 20px;
				text-align: left;

				.line1 {
					font-size: 35px;
					color: #0066cc;

					span {
						font-size: 16px;
						color: #fff;
					}
				}

				.line2 {
					font-size: 16px;
					color: #fff;
					margin-bottom: 20px;
				}
			}

			.conts-img {
				padding: 20px 0;
				text-align: center;

				img {
					width: 100%;
					max-width: 410px;
				}
			}
		}
	}

	.module4-conts {
		overflow: hidden;

		.module4-item {
			max-width: 380px;
			margin: 0 auto;
			padding: 10px;
			box-sizing: border-box;

			.conts {
				padding: 20px;
				box-sizing: border-box;
				background: #f5f5f5;
				min-height: 400px;
				transition: all 0.3s ease-in;

				&:hover {
					box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
					transform: translate3d(0, -2px, 0);
					background: rgba(124, 215, 243, 0.2);

					img {
						transform: scale(1.06);
					}
				}

				img {
					transition: all 0.3s ease-out;
					width: 100%;
				}

				h3 {
					font-size: 18px;
					color: #1f1f1f;
					line-height: 60px;
					border-bottom: 1px solid #e6e6e6;
				}

				p {
					font-size: 14px;
					color: #1f1f1f;
					padding: 20px 0;
				}
			}
		}
	}


	.module7-conts {
		.map-box {
			height: 500px;
			overflow: hidden;

			.bm-view {
				width: 100%;
				height: 100%;
			}
		}
	}

	// 移动端css
	@media screen and (max-width: 767px) {
		.the-banner {
			height: 260px;
		}

		.module4-conts {
			.module4-item {
				padding: 10px 0;
			}
		}
		.core-txt{
			margin-left: 10px;
			padding-right: 20px;
			display: flex;
			flex-direction: column;
		}
	}
</style>
